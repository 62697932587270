import * as React from 'react'

import type { HeadFC, PageProps } from 'gatsby'

import SEO from '@/components/Base/SEO'
import Layout from '@/components/Layout'
import NewsList from '@/components/News/List'
import PageTitle from '@/components/Parts/PageTitle'

type PageContext = {
  breadcrumb: {
    crumbs: {
      pathname: string
      crumbLabel: string
    }[]
  }
}

const NewsListPage = ({
  pageContext: {
    breadcrumb: { crumbs },
  },
}: PageProps<Queries.SitePage, PageContext>) => {
  return (
    <Layout logo={true} dark={true}>
      <main>
        <PageTitle
          title='おしらせ｡'
          page='news'
          crumbs={crumbs}
          crumbLabel='News / Event'
        />
        <NewsList />
      </main>
    </Layout>
  )
}

export default NewsListPage

export const Head: HeadFC = ({ location }) => (
  <SEO title='おしらせ｡' pathname={location.pathname} page='news' />
)
