import { getImage } from 'gatsby-plugin-image'
import scrollTo from 'gatsby-plugin-smoothscroll'
import * as React from 'react'
import {
  getQueryParams,
  useQueryParamString,
} from 'react-use-query-param-string'

import * as styles from '@/components/News/List/List.module.scss'
import Notice from '@/components/Parts/Notice'
import { useNews } from '@/hooks/useNews'

type Filters = 'お知らせ' | 'イベント' | 'メディア'

const isFilters = (value: any): value is Filters => {
  return value === 'お知らせ' || value === 'イベント' || value === 'メディア'
}

const NewsList = () => {
  const queryParams = getQueryParams()
  const [, setQueryPage] = useQueryParamString('page', '1')
  const [, setQueryFilter] = useQueryParamString('filter', '')

  const newsList = useNews()
  const perPage = 12
  const filters: (Filters | null)[] = [null, 'お知らせ', 'イベント', 'メディア']

  const [currentPage, setCurrentPage] = React.useState<number>(
    Number(queryParams['page'] ?? '1')
  )
  const handleCurrentPage = React.useCallback(
    (pageNumber: number) => {
      setCurrentPage(pageNumber)
      setQueryPage(String(pageNumber))
      setTimeout(() => scrollTo('#___gatsby'), 0)
    },
    [setQueryPage]
  )

  const [currentFilter, setCurrentFilter] = React.useState<Filters | null>(
    isFilters(queryParams['filter']) ? queryParams['filter'] : null
  )
  const handleCurrentFilter = React.useCallback(
    (filter: Filters | null) => {
      setCurrentPage(1)
      setQueryPage('1')
      setCurrentFilter(filter)
      setQueryFilter(filter ?? '')
    },
    [setQueryPage, setQueryFilter]
  )

  const filteredNewsList = newsList.filter((news) =>
    !currentFilter ? true : news.tags.some((tag) => tag.name === currentFilter)
  )

  const paginationList = [
    ...Array(Math.ceil(filteredNewsList.length / perPage)).keys(),
  ]
  const paginationLength = 7
  const centerIndex = currentPage - 1
  const halfLength = Math.floor(paginationLength / 2)
  const startIndex = Math.max(
    centerIndex -
      halfLength -
      Math.max(centerIndex + halfLength - paginationList.length + 1, 0),
    0
  )

  return (
    <section className={styles['news']}>
      <div className={styles['filters']}>
        {filters.map((filter, index) => (
          <button
            className={`${styles['filter']} ${
              filter === currentFilter ? styles['currentFilter'] : undefined
            }`}
            key={index}
            onClick={() => handleCurrentFilter(filter)}
            disabled={filter === currentFilter}
          >
            {filter ?? 'All'}
          </button>
        ))}
      </div>
      {filteredNewsList.length ? (
        <div className={styles['grid']}>
          {filteredNewsList
            .slice(
              perPage * (currentPage - 1),
              perPage * (currentPage - 1) + perPage
            )
            .map((news) => {
              const image = getImage(news.imgixImage.gatsbyImageData)
              return image ? (
                <Notice
                  key={news.newsId}
                  id={news.newsId}
                  title={news.title}
                  tags={news.tags}
                  date={news.publishedAt}
                  image={image}
                />
              ) : undefined
            })}
        </div>
      ) : (
        <div className={styles['empty']}>記事がありません｡</div>
      )}
      {filteredNewsList.length > perPage ? (
        <div className={styles['pagination']}>
          {paginationList
            .slice(startIndex, startIndex + paginationLength)
            .map((num) => (
              <button
                className={`${styles['page']} ${
                  currentPage === num + 1 ? styles['currentPage'] : undefined
                }`}
                key={num}
                onClick={() => handleCurrentPage(num + 1)}
                disabled={currentPage === num + 1}
              >
                {num + 1}
              </button>
            ))}
        </div>
      ) : undefined}
    </section>
  )
}

export default NewsList
